import { Tooltip as Tooltipcontainer, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

import { textVariants } from '@/components/ui/text';

function Tooltip({ wrapClasses, trigger, triggerClasses, content, contentAlign = 'center', isModal, ...props }) {
	const handleClick = (event) => {
		event.preventDefault(); // Prevent the default action (page reload)
	};

	return (
		<TooltipProvider>
			<Tooltipcontainer>
				<div className={cn('flex items-center', wrapClasses && wrapClasses)}>
					<TooltipTrigger className={triggerClasses} onClick={handleClick}>
						<div className="truncate-text">{trigger}</div>
					</TooltipTrigger>

					<TooltipContent
						align={contentAlign}
						className={cn(
							'tooltip-content overflow-y-scroll max-h-eis-266 md:max-w-eis-434 absolute right-eis-9 top-9 translate-x-full w-max max-w-[calc(100vw-3rem)]',
							// Note: fix a cut off display issue when use tooltip in the modal
							isModal && 'right-0 top-0 translate-x-[9px] -translate-y-full translate-x-0 origin-bottom-right max-w-50vw'
						)}
					>
						<div
							className={cn(
								// Note: use custom textVariants from text.js
								textVariants({ type: 'p', variant: 'normal' })
							)}
						>
							{typeof content === 'string' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
							{/* {content} */}
						</div>
					</TooltipContent>
				</div>
			</Tooltipcontainer>
		</TooltipProvider>
	);
}

export default Tooltip;
